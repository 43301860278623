import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import styled from '@emotion/styled';
import { $phoneWidth } from '../../styled/variables';
import { Infographic1, Infographic_m1, Infographic2, Infographic_m2, Infographic3, Infographic_m3 } from '../../assets/img';


export const Infographic: FC = () => {
  return (
    <Wrapper>
      <MyTitle>Премия «Учитель истории 2024» — в цифрах</MyTitle>
      <Descr>
      В этом году мы впервые провели нашу благотворительную премию. 
      У нас получилось сделать масштабный, по-настоящему всероссийский конкурс.
      <br /> Для учителей премия — один из способов ощутить профессиональную гордость 
      и познакомиться с коллегами со всей страны, а для нас — возможность 
      сделать вклад в отечественное образование.
        </Descr>
      <List>
        <Item>
          <img src={Infographic1} />
          <img src={Infographic_m1} />
        </Item>
        <Item>
          <img src={Infographic2} />
          <img src={Infographic_m2} />
        </Item>
        <Item>
          <img src={Infographic3} />
          <img src={Infographic_m3} />
        </Item>

      </List>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  display:flex;
  flex-direction:column;
  width:100%;
  padding-top: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 4rem;
  }
`;

const MyTitle = styled(Title)`
  margin-bottom: 3rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-bottom: 2rem;
    text-align: center;
  }
`;
const Descr = styled.p`
  font-family: 'Meta Pro';
  font-size: 2.1rem;
  font-weight: 400;
  text-align:center;
  line-height: 2.1rem;
  margin-bottom: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    line-height: 1.5rem;
    br {
      display: none;
    }
  }
`;

const List = styled.div`

  img {
    object-fit: cover;
    width:90%;
  }
  img:nth-child(2){
    display:none;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    img {
      object-fit: cover;
      width:97%;
    }
    img:nth-child(1){
      display:none;
    }
    img:nth-child(2){
      display:block;
    }
  }
`;
const Item = styled.div`
  &:nth-child(even){
    background-color: #1A4919;
    border-radius: 2.31rem;
    padding-bottom:6rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    &:nth-child(even){
      padding-bottom:2.5rem;
    }
    &:last-child{
      padding-top:4rem;
    }
    padding-bottom:3rem;
  }
  display:flex;
  justify-content:center;
  width:100%;
  padding: 2rem 0;
  padding-bottom:6rem;
  &:last-child{
    padding-bottom:2rem;
  }
`;

