import { FC } from 'react';
import { Container } from '../../styled/components';
import styled from '@emotion/styled';
import { Popsovet1, Popsovet2, Popsovet3, Popsovet4} from '../../assets/img';
import { $phoneWidth } from '../../styled/variables';

const config = [
  {
    avatar: Popsovet1,
    name: 'Валентина Куренкова',
    descr: 'Основатель неУниверситета и Фонда Первое Поколение, общественный деятель',
  },
  {
    avatar: Popsovet2,
    name: 'Иеромонах Геннадий (Войтишко)',
    descr: 'Руководитель Сектора приходского просвещения Синодального отдела религиозного образования и катехизации Русской Православной Церкви',
  },
  {
    avatar: Popsovet3,
    name: 'Алексей Калинин',
    descr: 'Председатель совета директоров группы компаний «Аквариус»',
  },
  {
    avatar: Popsovet4,
    name: 'Михаил Тихонов',
    descr: 'Советник директора Корпоративного университета московского образования',
  }
];

export const Popsovet: FC = () => {
  return (
    <Wrapper>
      <Title>Попечительский совет</Title>
      <List>
      {config.map(({ avatar, name, descr }) => (
          <Item key={name}>
            <Avatar src={avatar} alt={name} />
            <Name>{name}</Name>
            <Descr>{descr}</Descr>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding-top: 10rem;
  padding-bottom: 8rem;
  display: grid;
  align-content: baseline;
  grid-template-columns: 19.15rem 1fr;
  gap: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const Title = styled.div`
  font-size: 3.13rem;
  font-weight: 700;
  line-height: 2.81rem;
  text-transform: uppercase;
  text-align: left;
  @media screen and (max-width: ${$phoneWidth}) {
    text-align: center;
  }
`;

const List = styled.ul`
  display: grid;
  align-content: baseline;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    gap: 3rem;
    grid-template-columns: 1fr;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Avatar = styled.img`
  width: 17.8125rem;
  height: 17.8125rem;
  border-radius: 2.31rem;
  object-fit: cover;
  object-position: center top;
`;

const Name = styled.div`
  font-size: 1.88rem;
  font-weight: 700;
  line-height: 1.88rem;
`;

const Descr = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
`;
