import styled from '@emotion/styled';
import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import { $phoneWidth, $primaryBg } from '../../styled/variables';

// Импорт изображений
import partner1 from '../../assets/img/rggu.jpg'; // Замените на реальные пути к изображениям
import partner2 from '../../assets/img/aquarius.jpg';
import friend1 from '../../assets/img/nitka.jpg';
import friend2 from '../../assets/img/istorik.jpg';
import friend3 from '../../assets/img/friend-3.jpg';
import infoPartner1 from '../../assets/img/support-1.png';
import infoPartner2 from '../../assets/img/support-2.png';
import infoPartner3 from '../../assets/img/support-3.png';
import infoPartner4 from '../../assets/img/support-4.png';
import infoPartner5 from '../../assets/img/support-5.png';

const partners = [
  {
    name: 'Российский государственный гуманитарный университет',
    img: partner1,
  },
  {
    name: 'Группа компаний «Аквариус»',
    img: partner2,
  },
];

const friends = [
  {
    name: 'Русская чайная «Нитка»',
    img: friend1,
  },
  {
    name: 'Журнал «Историк»',
    img: friend2,
  },
  {
    name: 'Научно-популярные журналы «ДУМАЙ»',
    img: friend3,
  },
];

const infoPartners = [
  {
    avatar: infoPartner1,
    name: 'Сергей Волков',
    descr: 'Доктор исторических наук, профессор ПСТГУ',
  },
  {
    avatar: infoPartner2,
    name: 'Алексей Савватеев',
    descr: 'Просветитель, доктор физико-математических наук, член-корреспондент РАН',
  },
  {
    avatar: infoPartner3,
    name: 'Алексей Любжин',
    descr: 'Доктор филологических наук, специалист по истории российского образования',
  },
  {
    avatar: infoPartner4,
    name: 'Василий Тополев',
    descr: 'Блогер',
  },
  {
    avatar: infoPartner5,
    name: 'Василий Гурков',
    descr: 'Блогер',
  },
];

export const Support: FC = () => {
  return (
    <Wrapper id="support">
      <Container>
        <MyTitle>Нас поддерживают</MyTitle>
        
        <SectionTitle>Партнеры</SectionTitle>
        
        <List $count={partners.length}>
          {partners.map(({ name, img }) => (
            <Item key={name}>
              <Avatar src={img} alt={name} />
              <Name>{name}</Name>
            </Item>
          ))}
        </List>
        
        
        <SectionTitle>Друзья Фонда</SectionTitle>
        
        <List $count={friends.length}>
          {friends.map(({ name, img }) => (
            <Item key={name}>
              <Avatar src={img} alt={name} />
              <Name>{name}</Name>
            </Item>
          ))}
        </List>
        
        
        <SectionTitle>Информационные партнеры</SectionTitle>
        <List $count={infoPartners.length}>
          {infoPartners.map(({ avatar, name, descr }) => (
            <Item key={name}>
              <Avatar src={avatar} />
              <Name>{name}</Name>
              <Descr>{descr}</Descr>
            </Item>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  padding: 3.5rem 0;
  color: #fff;
  background-color: ${$primaryBg};
  border-top: 0.25rem solid rgb(38, 96, 37);
  border-bottom: 0.25rem solid rgb(38, 96, 37);
  ul:not(:last-child) {
    display:flex;
    justify-content:center;
    li {
      margin:0;
      width:20rem;
    }
  }
  @media screen and (max-width: ${$phoneWidth}) {
    ul:not(:last-child) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    li {
      margin:0 auto;
      width:20rem;
    }
  }
  }
`;

const MyTitle = styled(Title)`
  margin-bottom: 4.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    text-align: center;
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  margin: 2rem 0;
  margin-top:3.5rem;
`;

const List = styled.ul<{ $count: number }>`
  display: grid;
  align-content: baseline;
  grid-template-columns: ${({ $count }) => `repeat(${$count}, 1fr)`};
  justify-content: space-between;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 20rem;
  margin: 0 auto;
  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 17.8125rem;
  height: 17.8125rem;
  border-radius: 2.31rem;
`;

const Name = styled.div`
  font-size: 1.88rem;
  font-weight: 700;
  line-height: 1.88rem;
`;

const Descr = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
`;
