import { FC } from 'react';
import { Offer } from './Offer';
import { Values } from './Values';
import { Infographic } from './Infographic';
// import { HowWork } from './HowWork';
// import { Rewards } from './Rewards';
import { HelmetHead } from '../../components/HelmetHead';

const Main: FC = () => {
  return (
    <>
      <HelmetHead title="Фонд Румянцева" descr="Фонд Румянцева" />
      <Offer />
      <Values />
      <Infographic />
      {/* <HowWork /> */}
      {/* <Rewards /> */}
    </>
  );
};

export default Main;
